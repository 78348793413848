'use client';

import React from 'react';

import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react';

const BugsnagContext = React.createContext(undefined);

export function start() {
    if (process.env.NEXT_PHASE !== "phase-production-build") {
        const options = {
            apiKey: process.env.NEXT_APP_BUGSNAG_API_KEY ?? '',
            appVersion: process.env.NEXT_APP_BUGSNAG_APP_VERSION ?? '',
            releaseStage: process.env.NEXT_APP_BUGSNAG_RELEASE_STAGE ?? 'development',
            plugins: [new BugsnagPluginReact()],
            enabledReleaseStages: ['production', 'staging', 'development'],
        }

        const performanceOptions = {
            ...options,
            plugins: [],
        }

        Bugsnag.start(options)
        BugsnagPerformance.start(performanceOptions);

        return Bugsnag;
    } else {
        return null;
    }
}

export function BugsnagProvider({ children }) {
    const [instance, setInstance] = React.useState(start());
    return (
        <BugsnagContext.Provider value={[instance, setInstance]}>
            {children}
        </BugsnagContext.Provider>
    );
}

export function useBugsnag() {
    const context = React.useContext(BugsnagContext);
    if (context === undefined) {
        throw new Error('useBugsnag must be used within a BugsnagProvider');
    }
    return context;
}